// extracted by mini-css-extract-plugin
export var carouselContainer = "H_wl d_v d_G d_bd d_Y";
export var carouselContainerCards = "H_wm H_wl d_v d_G d_bd d_Y";
export var carouselContainerSides = "H_wn d_v d_G d_Y";
export var prevCarouselItem = "H_wp d_v d_G d_Z d_j";
export var prevCarouselItemL = "H_wq H_wp d_v d_G d_Z d_j";
export var moveInFromLeft = "H_wr";
export var prevCarouselItemR = "H_ws H_wp d_v d_G d_Z d_j";
export var moveInFromRight = "H_wt";
export var selectedCarouselItem = "H_wv d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "H_ww H_wv d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "H_wx H_wv d_v d_G d_Y d_bd";
export var nextCarouselItem = "H_wy d_v d_G d_Z d_j";
export var nextCarouselItemL = "H_wz H_wy d_v d_G d_Z d_j";
export var nextCarouselItemR = "H_wB H_wy d_v d_G d_Z d_j";
export var arrowContainer = "H_wC d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "H_wD H_wC d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "H_wF H_wD H_wC d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "H_wG H_wC d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "H_wH";
export var nextArrowContainerHidden = "H_wJ H_wG H_wC d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "H_kH d_Z";
export var prevArrow = "H_wK H_kH d_Z";
export var nextArrow = "H_wL H_kH d_Z";
export var carouselIndicatorContainer = "H_wM d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "H_wN d_v d_by d_bD";
export var carouselText = "H_wP d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "H_wQ H_wP d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "H_wR d_b6";
export var carouselIndicator = "H_wS H_wR d_b6";
export var carouselIndicatorSelected = "H_wT H_wR d_b6";
export var arrowsContainerTopRight = "H_wV d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "H_wW d_Z d_bk d_bB";
export var arrowsContainerSides = "H_wX d_Z d_bk d_bB";
export var smallArrowsBase = "H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "H_wZ H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "H_w0 H_wZ H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "H_w1 H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "H_w2 H_w1 H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "H_pD";
export var iconSmall = "H_w3";
export var multipleWrapper = "H_w4 d_bB d_bD d_bd";
export var multipleImage = "H_w5 d_bB";
export var sidesPrevContainer = "H_w6 H_wZ H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "H_w7 H_wZ H_wY d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";