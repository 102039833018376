// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_tk d_bC";
export var storyRowWrapper = "y_hx d_hx d_bJ";
export var storyLeftWrapper = "y_tl d_by d_bN";
export var storyWrapperFull = "y_tm d_cC";
export var storyWrapperFullLeft = "y_nN d_cC d_by d_bN d_bC";
export var contentWrapper = "y_pb d_hy";
export var storyLeftWrapperCenter = "y_tn d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "y_tp d_hF";
export var storyHeader = "y_tq d_hD d_v d_cr";
export var storyHeaderCenter = "y_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "y_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "y_tr d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "y_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "y_qV d_ff d_Y";
export var imageWrapperFull = "y_qW d_v d_G d_bd d_Y";