// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sL d_gv d_cr";
export var heroHeaderCenter = "w_gw d_gw d_cr d_dv";
export var heroHeaderRight = "w_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "w_sM d_gr d_cv";
export var heroParagraphCenter = "w_gs d_gs d_cv d_dv";
export var heroParagraphRight = "w_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "w_sN d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "w_sP d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "w_sQ d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "w_sR d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "w_sS d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "w_sT F_sT";
export var heroExceptionNormal = "w_sV F_sV";
export var heroExceptionLarge = "w_sW F_sW";
export var Title1Small = "w_sX F_sX F_tN F_tP";
export var Title1Normal = "w_sY F_sY F_tN F_tQ";
export var Title1Large = "w_sZ F_sZ F_tN F_tR";
export var BodySmall = "w_s0 F_s0 F_tN F_t5";
export var BodyNormal = "w_s1 F_s1 F_tN F_t6";
export var BodyLarge = "w_s2 F_s2 F_tN F_t7";