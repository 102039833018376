import React from 'react';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../../helper';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import Image from '../../../../Image';

import * as styles from './styles.module.css';

class HeroSliderLayout extends React.PureComponent {
  static createStyle = (colorObj, colors) => {
    let backgroundColor = { solid: '#ffffff' };
    let opacity = 0;

    if (colorObj && colorObj.active && colorObj.solid) {
      backgroundColor = { solid: colorObj.solid };
      ({ opacity } = colorObj);
    }

    return formColor(backgroundColor, false, opacity, undefined, colors);
  };

  constructor(props) {
    super(props);

    const dataIndex = props.section.data.findIndex((d) => d.type === 'DATA');
    const rec = props.section;
    const showBullets = rec ? rec.data[dataIndex].showBullets : false;
    const showArrows = rec ? rec.data[dataIndex].showArrows : false;
    const interval = rec ? rec.data[dataIndex].interval : 10; // seconds
    const autoplayEnabled = rec ? rec.data[dataIndex].autoplayEnabled : false;
    const loopEnabled = rec ? rec.data[dataIndex].loopEnabled : false;
    const slideTransition = rec ? rec.data[dataIndex].slideTransition : 'noanimation';

    const slides = [];
    if (props.section && props.section.data) {
      props.section.data.forEach((dataSlot) => {
        if (dataSlot.type === 'COMPONENT') slides.push(dataSlot);
      });
    }

    const playerId = Math.random().toString(36).substr(2, 10);

    this.state = {
      currentSlideIndex: 0,
      videoLoaded: false,
      slides,
      showBullets,
      showArrows,
      interval,
      autoplayEnabled,
      loopEnabled,
      slideTransition,
      playerId,
      startPos: undefined,
    };
  }

  componentDidMount() {
    this.handleInterval();

    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('message', this.handleMessageReceived, false);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window.removeEventListener) {
      window.removeEventListener('message', this.handleMessageReceived, false);
    }
  }

  handleMessageReceived = (e) => {
    if (e && e.origin && e.origin === 'https://player.vimeo.com' && e.data) {
      const data = JSON.parse(e.data);
      if (data.player_id === this.state.playerId && data.event === 'ready') this.setState({ videoLoaded: true });
    }
  };

  handleInterval = () => {
    if (this.state.autoplayEnabled) {
      let newIndex;
      let end = false;

      if (this.state.loopEnabled) {
        newIndex =
          this.state.currentSlideIndex + 1 > this.state.slides.length - 1 ? 0 : this.state.currentSlideIndex + 1;
      } else if (this.state.currentSlideIndex + 1 > this.state.slides.length - 1) {
        end = true;
        newIndex = this.state.currentSlideIndex;
      } else {
        newIndex = this.state.currentSlideIndex + 1;
      }

      if (!end) {
        setTimeout(() => {
          this.setState(
            {
              currentSlideIndex: newIndex,
              videoLoaded: false,
              prevSlideIndex: this.state.currentSlideIndex,
              alternate: !this.state.alternate,
            },
            () => {
              this.handleInterval();
            },
          );
        }, this.state.interval * 1000);
      }
    }
  };

  handleDotClick = (index) => {
    this.setState({
      currentSlideIndex: index,
      videoLoaded: false,
      prevSlideIndex: this.state.currentSlideIndex,
      alternate: !this.state.alternate,
    });
  };

  handleTouch = (e, start) => {
    if (e) {
      e.stopPropagation();
      const [client] = e.changedTouches;
      if (start && client) this.setState({ startPos: client.clientX });
      else if (client && this.state.startPos !== undefined) {
        let { currentSlideIndex } = this.state;
        if (this.state.startPos > client.clientX) {
          if (currentSlideIndex < this.state.slides.length - 1) currentSlideIndex += 1;
        } else if (currentSlideIndex > 0) currentSlideIndex -= 1;

        this.setState({ startPos: undefined, currentSlideIndex });
      }
    }
  };

  renderSlide = (slide) => {
    if (!slide || !slide.data || slide.data.length !== 5) return null;

    const align = slide && slide.data && slide.data[4] && slide.data[4].align ? slide.data[4].align : 'Left';

    const headerAlignStyle = styles[`heroHeader${align}`];
    const paragraphAlignStyle = styles[`heroParagraph${align}`];
    const exceptionStyle = styles[`heroException${this.props.themeData.typography.default.fontSize}`];
    const btnWrapper = styles[`heroSliderBtnWrapper${align}`];

    const buttons = [];
    const texts = [];
    slide.data.forEach((item, index) => {
      if (item.active) {
        let text;
        let type;
        if (item.type.startsWith('HEADINGS') || item.type.startsWith('PARAGRAPH')) {
          if (item.type === 'HEADINGS/HEADING-ONE') {
            text = `<h1>${item.text}</h1>`;
            type = 'h1';
          } else if (item.type === 'PARAGRAPH/PARAGRAPH') {
            text = `<span>${item.text}</span>`;
            type = 'p';
          }
        }

        if (text !== undefined) {
          const res = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={type === 'h1' ? headerAlignStyle : `${paragraphAlignStyle} ${exceptionStyle}`}
            >
              {HTMLParser(text)}
            </div>
          );

          texts.push(res);
        } else if (item.type.startsWith('BUTTON')) {
          buttons.push(
            <div key={`${this.props.section._id}_layout_${index}`} className={styles.btnWrapper}>
              <ButtonEditor
                buttons={this.props.buttons}
                themeData={this.props.themeData}
                data={item.content}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
                categoryPathList={this.props.categoryPathList}
                sectionSlugs={this.props.sectionSlugs}
              />
            </div>,
          );
        }
      }

      return null;
    });

    return (
      <div
        style={{ width: '100%' }}
        onTouchStart={(e) => this.handleTouch(e, true)}
        onTouchEnd={(e) => this.handleTouch(e, false)}
      >
        <div className="col-12 col-sm-10 offset-sm-1">
          {texts}
          <div className={btnWrapper}>{buttons}</div>
        </div>
      </div>
    );
  };

  renderDots = () => {
    if (this.state.showBullets) {
      const dots = [];

      this.state.slides.map((slide, index) => {
        const dot = (
          <span
            key={`heroSlider_dot_${index}`}
            className={this.state.currentSlideIndex === index ? styles.dotActive : styles.dot}
            onClick={() => this.handleDotClick(index)}
          />
        );

        dots.push(dot);

        return dot;
      });

      return <div className={`col-12 ${styles.dotsWrapper}`}>{dots}</div>;
    }

    return null;
  };

  handleArrowClick = (value) => {
    this.setState({
      currentSlideIndex: value,
      videoLoaded: false,
      prevSlideIndex: this.state.currentSlideIndex,
      alternate: !this.state.alternate,
    });
  };

  renderArrows = () => {
    if (this.state.showArrows) {
      const arrows = [];

      if (this.state.currentSlideIndex > 0) {
        const arrowLeft = (
          <span
            key="arrowLeft"
            className={`entypo icon-left-open ${styles.arrowLeft}`}
            onClick={() => this.handleArrowClick(this.state.currentSlideIndex - 1)}
          />
        );

        arrows.push(arrowLeft);
      }

      if (this.state.currentSlideIndex < this.state.slides.length - 1) {
        const arrowRight = (
          <span
            key="arrowRight"
            className={`entypo icon-right-open ${styles.arrowRight}`}
            onClick={() => this.handleArrowClick(this.state.currentSlideIndex + 1)}
          />
        );

        arrows.push(arrowRight);
      }

      return <>{arrows}</>;
    }

    return null;
  };

  createSlide = (slideIndex) => {
    let bgStyle;
    let videoOverlay;
    let image;
    const data4 =
      this.state.slides[slideIndex] && this.state.slides[slideIndex].data
        ? this.state.slides[slideIndex].data[4]
        : undefined;
    const { videoURL = '' } = data4 || {};
    if (data4) {
      if (data4.styles && data4.styles.bg && data4.styles.bg.active) {
        bgStyle = HeroSliderLayout.createStyle(data4.styles.bg, this.props.themeData.colors);
      }

      if (data4.styles && data4.styles.video && data4.styles.video.active) {
        videoOverlay = HeroSliderLayout.createStyle(data4.styles.video, this.props.themeData.colors);
      }

      if (
        (data4.image.active && data4.image.id) ||
        (data4.styles && data4.styles.backgroundColor && data4.styles.backgroundColor.active)
      ) {
        let img;
        let bgOverlay;
        if (data4.styles && data4.styles.backgroundColor && data4.styles.backgroundColor.active) {
          bgOverlay = HeroSliderLayout.createStyle(data4.styles.backgroundColor, this.props.themeData.colors);
        }

        if (data4.image.active && data4.image.id) {
          img = (
            <Image
              id={data4.image.id}
              alt=""
              imageStyleName="heroSliderBackgroundImage"
              sizes="100vw"
              images={this.props.images}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
          );
        }

        image = (
          <>
            {img}
            {bgOverlay && <div className={styles.videoOverlay} style={bgOverlay} />}
          </>
        );
      }
    }

    let vimeoURL;
    if (videoURL) {
      const { startsAtMin } = data4 || 0;
      const { startsAtSec } = data4 || 0;
      const loopVideo = data4.loopVideo ? 1 : 0;
      const regExp =
        /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      const match = videoURL.match(regExp);

      let video;
      if (match && match[1]) [, video] = match;
      if (video)
        vimeoURL = `https://player.vimeo.com/video/${video}?background=true&api=1&responsive=true&loop=${loopVideo}&player_id=${this.state.playerId}#t=${startsAtMin}m${startsAtSec}s`;
    }

    if (
      (image || (data4 && data4.loadingImage && data4.loadingImage.active && data4.loadingImage.id)) &&
      vimeoURL &&
      !this.state.videoLoaded
    ) {
      if (data4.loadingImage.active && data4.loadingImage.id) {
        image = (
          <div className={styles.loadingWrapper}>
            <Image
              id={data4.loadingImage.id}
              alt=""
              imageStyleName="heroSliderBackgroundImage"
              sizes="100vw"
              images={this.props.images}
              loadingStrategy={this.props.isFirst ? 'eager' : undefined}
            />
            {videoOverlay && <div className={styles.videoOverlay} style={videoOverlay} />}
          </div>
        );
      }
    }

    let video;
    if (vimeoURL) {
      video = (
        <>
          <div className={styles.frameWrapper}>
            {slideIndex === this.state.currentSlideIndex && (
              <iframe
                className={styles.videoFrame}
                src={vimeoURL}
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                loading={this.props.isFirst ? 'eager' : 'lazy'}
              />
            )}
          </div>
          {videoOverlay && <div className={styles.videoOverlay} style={videoOverlay} />}
        </>
      );
    }

    const slide = this.renderSlide(this.state.slides[slideIndex], slideIndex);
    return (
      <React.Fragment key={`${this.props.section._id}_Slide_${slideIndex}`}>
        <div className={styles.background} style={bgStyle}>
          {video}
          {!this.state.videoLoaded && image}
        </div>
        {slide}
      </React.Fragment>
    );
  };

  createSlides = () => {
    const slides = [];
    if (
      this.state.slideTransition === 'noanimation' ||
      this.state.slides.length === 1 ||
      this.state.prevSlideIndex === undefined
    ) {
      slides.push(this.createSlide(this.state.currentSlideIndex));
    } else {
      [this.state.prevSlideIndex, this.state.currentSlideIndex].forEach((slideIndex, i) => {
        let slideStyle;
        if (slideIndex === this.state.currentSlideIndex) slideStyle = `top${this.state.alternate ? 'Alt' : ''}`;
        else if (slideIndex === this.state.prevSlideIndex) slideStyle = `bottom${this.state.alternate ? 'Alt' : ''}`;
        slides.push(
          <div key={`${this.props.section._id}_Slide_${i}_${slideStyle}`} className={styles[slideStyle]}>
            {this.createSlide(slideIndex)}
          </div>,
        );
      });
    }

    return slides;
  };

  render() {
    const slides = this.createSlides();

    return (
      <div className={styles.wrapper}>
        {slides}
        {this.renderArrows()}
        {this.renderDots()}
      </div>
    );
  }
}

export default HeroSliderLayout;
