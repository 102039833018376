// extracted by mini-css-extract-plugin
export var alignLeft = "z_pL d_fn d_bF d_dt";
export var alignCenter = "z_bN d_fp d_bC d_dv";
export var alignRight = "z_pM d_fq d_bG d_dw";
export var testimonialsContainer = "z_ts d_dV";
export var testimonialsContainerFull = "z_tt d_dS";
export var testimonialsMainHeader = "z_jw d_jw d_v d_cv";
export var testimonialsComponentParagraph = "z_jy d_jy";
export var testimonialsComponentSmall = "z_jx d_jx";
export var testimonialsComponentsWrapper = "z_js d_js d_x d_v d_ct d_b2 d_b4";
export var testimonialsComponentsWrapperDesign2 = "z_jt d_jt d_x d_v d_ct d_b2 d_b4 d_bB";
export var testimonialsComponentsWrapperDesign3 = "z_jv d_jv d_x d_v d_G d_b4 d_bS d_by d_bH d_bN d_bC";
export var testimonialsBackgroundColor = "z_tv d_d6";
export var colEqualHeight = "z_tw d_by";
export var testimonialsColumnWrapper = "z_jz d_jz d_b2";
export var testimonialsImageWrapper = "z_jp d_jp d_v d_Y";
export var testimonialsImageWrapperDesign2 = "z_jq d_jq d_q d_Y d_bw";
export var testimonialsImageWrapperDesign3 = "z_jr d_jr d_Y d_by";
export var design2TextWrapper = "z_tx d_bB d_bN d_bH";
export var design3 = "z_ty d_cC d_G";
export var imageDesign2 = "z_tz";
export var SmallSmall = "z_q2 F_q2 F_tN F_t8";
export var SmallNormal = "z_q3 F_q3 F_tN F_t9";
export var SmallLarge = "z_q4 F_q4 F_tN F_t6";
export var exceptionWeight = "z_sB F_vd";