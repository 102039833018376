// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_r4 d_fn d_bF d_dt";
export var alignLeft = "t_pL d_fn d_bF d_dt";
export var alignDiscCenter = "t_r5 d_fp d_bC d_dv";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignDiscRight = "t_r6 d_fq d_bG d_dw";
export var alignRight = "t_pM d_fq d_bG d_dw";
export var footerContainer = "t_r7 d_dV d_bV";
export var footerContainerFull = "t_r8 d_dS d_bV";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_r9 d_v";
export var footerDisclaimerWrapper = "t_km d_km d_cj";
export var badgeWrapper = "t_sb d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "t_sc undefined";
export var footerDisclaimerLeft = "t_sd undefined";
export var verticalTop = "t_sf d_by d_bH d_bM d_bK";
export var firstWide = "t_sg";
export var disclaimer = "t_sh d_by d_bH";
export var socialDisclaimer = "t_sj";
export var left = "t_sk";
export var wide = "t_sl d_cx";
export var right = "t_sm d_bJ";
export var line = "t_fj d_fk d_ct";
export var badgeDisclaimer = "t_sn d_bB d_bN d_bH";
export var badgeContainer = "t_sp d_by d_bG d_bN";
export var badge = "t_sq";
export var padding = "t_sr d_c6";
export var end = "t_ss d_bG";
export var linkWrapper = "t_st d_dz";
export var link = "t_mC d_dz";
export var colWrapper = "t_sv d_cw";
export var consent = "t_f d_f d_bB d_bN";
export var media = "t_sw d_bw d_dx";
export var itemRight = "t_sx";
export var itemLeft = "t_sy";
export var itemCenter = "t_sz";
export var exceptionWeight = "t_sB F_vd";