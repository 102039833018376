// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "v_jQ d_jQ d_ct";
export var galleryMasonryImage = "v_jP d_jP d_v d_bQ d_dz";
export var alignLeft = "v_pL d_fn d_bF d_dt";
export var alignCenter = "v_bN d_fp d_bC d_dv";
export var alignRight = "v_pM d_fq d_bG d_dw";
export var galleryContainer = "v_sC d_dV";
export var galleryContainerFull = "v_sD d_dS";
export var galleryRowWrapper = "v_sF d_cb";
export var galleryGuttersImage = "v_jS d_jS d_J d_ct";
export var galleryNoGuttersImage = "v_jR d_jR d_J d_cC";
export var galleryTextGutters = "v_jM d_jM d_cv";
export var galleryTextNoGutters = "v_jN d_jN d_cv";
export var galleryTextMasonry = "v_sG d_jM d_cv";
export var galleryImageWrapper = "v_sH d_ff d_Y";
export var descText = "v_sJ d_jT d_Z d_8 d_6 d_7 d_m";
export var guttersDesc = "v_sK";