// extracted by mini-css-extract-plugin
export var lbContainerOuter = "J_w8";
export var lbContainerInner = "J_w9";
export var movingForwards = "J_xb";
export var movingForwardsOther = "J_xc";
export var movingBackwards = "J_xd";
export var movingBackwardsOther = "J_xf";
export var lbImage = "J_xg";
export var lbOtherImage = "J_xh";
export var prevButton = "J_xj";
export var nextButton = "J_xk";
export var closing = "J_xl";
export var appear = "J_xm";