// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sL d_gv d_cr";
export var heroHeaderCenter = "x_gw d_gw d_cr d_dv";
export var heroHeaderRight = "x_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "x_sM d_gr d_cv";
export var heroParagraphCenter = "x_gs d_gs d_cv d_dv";
export var heroParagraphRight = "x_gt d_gt d_cv d_dw";
export var heroSliderBtnWrapperLeft = "x_s3 d_d1 d_d0 d_v d_by d_bF d_bH";
export var heroSliderBtnWrapperCenter = "x_s4 d_d2 d_d0 d_v d_by d_bC d_bH";
export var heroSliderBtnWrapperRight = "x_s5 d_d3 d_d0 d_v d_by d_bG d_bH";
export var btnWrapper = "x_d1";
export var wrapper = "x_s6 d_by d_bN d_v d_G";
export var background = "x_qc d_bc d_Z d_7 d_6 d_3 d_8";
export var frameWrapper = "x_s7 d_v d_G d_Y d_3 d_6 d_bj d_bd d_bt";
export var videoFrame = "x_s8 d_Z";
export var videoOverlay = "x_s9 d_v d_G d_Z d_3";
export var arrow = "x_kH d_Z d_m";
export var arrowLeft = "x_tb x_kH d_Z d_m";
export var arrowRight = "x_tc x_kH d_Z d_m";
export var buttonsWrapper = "x_td d_by d_bN";
export var dotsWrapper = "x_tf d_by d_bN d_bC d_Z";
export var dot = "x_tg d_bw d_dz";
export var dotActive = "x_th x_tg d_bw d_dz";
export var heroSliderBackgroundImage = "x_gz d_v d_G d_bQ d_bj";
export var loadingWrapper = "x_tj d_bc d_Z d_7 d_6 d_3 d_8";
export var topAlt = "x_gC d_gC d_gB d_by d_bN d_v";
export var top = "x_gD d_gD d_gB d_by d_bN d_v";
export var bottomAlt = "x_gG d_gG d_bc d_Z d_7 d_6 d_3 d_8 d_gB d_by d_bN";
export var bottom = "x_gH d_gH d_bc d_Z d_7 d_6 d_3 d_8 d_gB d_by d_bN";
export var heroExceptionSmall = "x_sT F_sT";
export var heroExceptionNormal = "x_sV F_sV";
export var heroExceptionLarge = "x_sW F_sW";