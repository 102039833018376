// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "G_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "G_fR d_fR d_by d_bH";
export var menuDesign6 = "G_vS d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "G_vT d_fM d_by d_dv d_bN d_bH";
export var menuRight = "G_vV d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "G_vW d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "G_vX d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "G_p6 d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "G_qh d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "G_vY";
export var navbarItem = "G_p7 d_bw";
export var navbarLogoItemWrapper = "G_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "G_vZ d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "G_v0 d_gd d_bx d_Y d_br";
export var burgerToggle = "G_v1 d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "G_v2 d_gd d_bx d_Y d_br";
export var burgerInput = "G_v3 d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "G_v4 d_f3 d_v d_G";
export var burgerLine = "G_v5 d_f1";
export var burgerMenuDesign6 = "G_v6 d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "G_v7 d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "G_v8 d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "G_v9 d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "G_wb d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "G_wc d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "G_wd d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "G_wf d_bB d_bN";
export var staticBurger = "G_wg";
export var menu = "G_wh";
export var navbarDividedLogo = "G_wj";
export var nav = "G_wk";